import React, { useState, useEffect, useRef } from 'react';
import {
    Box, Button, TextField, Typography, Popper, Paper, ClickAwayListener
} from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import Draggable from 'react-draggable';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import apiConfig from '../apiConfig';

interface SelectEcwPcpProps {
    selectedPcp: any;
    setSelectedPcp: (pcp: any) => void;
}

function DraggablePaperComponent(props: any) {
    return (
        <Draggable handle="#draggable-popper-title">
            <Paper elevation={3} {...props} />
        </Draggable>
    );
}

export const SelectEcwPcp: React.FC<SelectEcwPcpProps> = ({ selectedPcp, setSelectedPcp }) => {
    const apiURL = apiConfig.REACT_APEX_BASE_API;
    const gridRef = useRef<any>(null);
    const initFocusRef = useRef<HTMLInputElement>(null);
    const anchorElRef = useRef<HTMLDivElement>(null);
    const [quickPcpFilter, setQuickPcpFilter] = useState('');
    const [pcpList, setPcpList] = useState<any[]>([]);
    const [open, setOpen] = useState(false);
    const [npi, setNpi] = useState('');
    const [responseMessage, setResponseMessage] = useState<string | null>(null);

    const fetchPcpList = async () => {
        const sqlText = `select doctorid, npi, ulname, ufname, uminitial,sex, suffix, countrycode, upcity, upstate,speciality, upphone , FaxNo from mobiledoc.dbo.doctors doc join mobiledoc.dbo.users usr on usr.uid = doc.doctorid`;
        const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        try {
            const response = await axios.get(`${urlPrefix}${sqlText}`, {headers: authHeader});

            if (response.data) {
                const dframe: string = response.data.frame0;
                const myObj = JSON.parse(dframe);
                const gridData = myObj.rows;
                setPcpList(gridData);
            }
        } catch (error) {
            console.error('Error fetching PCP list:', error);
        }
    };

    const addPcpByNpi = async (npi: string) => {
        try {
            const response = await axios.get(`${apiURL}/ecwAddPcp?npi=${npi}`);
            setResponseMessage(`PCP with NPI ${npi} added successfully.`);
            await fetchPcpList();
            const addedPcp = response.data;
            setSelectedPcp(addedPcp);
        } catch (error) {
            console.error('Error adding PCP by NPI:', error);
            setResponseMessage(`Failed to add PCP with NPI ${npi}.`);
        }
    };

    useEffect(() => {
        fetchPcpList();
    }, []);

    useEffect(() => {
        if (open && initFocusRef.current) {
            initFocusRef.current.focus();
        }
    }, [open]);

    const handleQuickPcpFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setQuickPcpFilter(value);

        if (gridRef.current) {
            gridRef.current.api.setQuickFilter(value);
        }
    };

    const clearQuickPcpFilter = () => {
        setQuickPcpFilter('');
        if (gridRef.current) {
            gridRef.current.api.setQuickFilter('');
        }
    };

    const handleGridRowSelected = (event: any) => {
        const selectedNodes = event.api.getSelectedNodes().filter((node: any) => node.selected);
        const datarow = selectedNodes[0].data;
        setSelectedPcp(datarow);
        setQuickPcpFilter(datarow.ulname);
    };

    const onGridReady = (params: any) => {
        // Add any grid initialization code here
    };

    if (!pcpList) return null;

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Box ref={anchorElRef}>
                <Box display="flex" alignItems="center" justifyContent="space-between" border="1px solid" padding="8px" onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>
                    {selectedPcp ? (
                        <Typography variant="body1">
                            Doc: {`${selectedPcp.ulname}, ${selectedPcp.ufname} [${selectedPcp.doctorid}] [${selectedPcp.npi}]`}
                        </Typography>
                    ) : (
                        <Typography variant="body1">Select PCP...</Typography>
                    )}
                    {selectedPcp && (
                        <Button size="small" onClick={(e) => { e.stopPropagation(); setSelectedPcp(null); }}>
                            X
                        </Button>
                    )}
                </Box>
                <Popper open={open} anchorEl={anchorElRef.current} placement="bottom" disablePortal style={{ zIndex: 1300 }}>
                    <DraggablePaperComponent style={{ width: 650 }}>
                        <Box p={2} display="flex" justifyContent="space-between" alignItems="center" style={{ cursor: 'move' }}>
                            <Typography variant="h6" id="draggable-popper-title">Select PCP</Typography>
                            <Button size="small" onClick={() => setOpen(false)}>X</Button>
                        </Box>
                        <Box display="flex" mt={2}>
                            <TextField
                                label="NPI Number"
                                variant="outlined"
                                size="small"
                                value={npi}
                                onChange={(e) => setNpi(e.target.value)}
                                style={{ marginLeft: '12px' }}
                            />
                            <Button
                                onClick={() => addPcpByNpi(npi)}
                                color="primary"
                                variant="contained"
                                style={{ marginLeft: '8px' }}
                            >
                                Add Pcp
                            </Button>
                            {responseMessage && (
                                <Box mt={2}>
                                    <Typography variant="body2">{responseMessage}</Typography>
                                </Box>
                            )}
                        </Box>

                        <Box p={2}>
                            <Box display="flex" alignItems="center" mb={2}>
                                <TextField
                                    value={quickPcpFilter}
                                    onChange={handleQuickPcpFilter}
                                    placeholder="Search PCP"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    InputProps={{
                                        endAdornment: quickPcpFilter && (
                                            <Button size="small" onClick={clearQuickPcpFilter}>
                                                X
                                            </Button>
                                        ),
                                    }}
                                    inputRef={initFocusRef}
                                />
                                <Button
                                    style={{ marginLeft: '8px', border: '2px solid green', minWidth: '40px' }}
                                    onClick={fetchPcpList}
                                >
                                    Refresh
                                </Button>
                            </Box>
                            <Box className="ag-theme-alpine" style={{ width: '100%', height: '250px' }}>
                                <AgGridReact
                                    ref={gridRef}
                                    onGridReady={onGridReady}
                                    rowData={pcpList}
                                    rowHeight={20}
                                    columnDefs={[
                                        { field: 'doctorid', headerName: 'ID', width: 90 },
                                        { field: 'npi', headerName: 'NPI', width: 120 },
                                        { field: 'ulname', headerName: 'Last Name', width: 150 },
                                        { field: 'ufname', headerName: 'First Name', width: 150 },
                                        { field: 'speciality', headerName: 'Specialty', width: 150 },
                                        { field: 'upcity', headerName: 'City', width: 120 },
                                        { field: 'upstate', headerName: 'State', width: 80 },
                                    ]}
                                    rowSelection="single"
                                    onSelectionChanged={handleGridRowSelected}
                                />
                            </Box>

                        </Box>
                    </DraggablePaperComponent>
                </Popper>
            </Box>
        </ClickAwayListener>
    );
};

export default SelectEcwPcp;
