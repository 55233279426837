import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    TextField,
    RadioGroup,
    FormControl,
    FormControlLabel,
    Radio,
    Typography,
    Collapse,
    IconButton,
    Card,
    CardHeader,
    CardContent,
    FormLabel
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import SelectEcwPatient from "../../Modals/SelectApexPatient";
import { makeStyles } from '@mui/styles';
import axios, {AxiosResponse} from 'axios';
import ClearIcon from "@mui/icons-material/Clear";
import apiConfig from "../../apiConfig";
import SelectEcwPcp from "../../Modals/SelectEcwPcp";
import { usePatient } from '../../Contexts/PatientContext';

interface PatientData {
    uid: number | null;
    ulname: string;
    ufname: string;
    uminitial: string;
    suffix: string;
    prefix: string;
    dob: string;
    sex: string;
    upPhone: string;
    umobileno: string;
    uemail: string;
    upaddress: string;
    upaddress2: string;
    upcity: string;
    upstate: string;
    zipcode: string;
    maritalstatus: string;
    race: string;
    ethnicity: string;
    doctorId: number;
    refPrId: number;
    rendPrId: number;
    nostatements: number;
}

interface EcwPatientFormProps {
    onPatientChange: (patient: any) => void;
    patientContextID?: number;
}

const useStyles = makeStyles(() => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        backgroundColor: '#f4f6f8',
        padding: '16px',
        borderRadius: '8px',
    },
    formField: {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        '& .MuiInputBase-root': {
            fontSize: '0.875rem',
        },
        '& .MuiFormLabel-root': {
            fontSize: '0.875rem',
        },
    },
    formFieldRow: {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        '& > *': {
            flex: 1,
        },
    },
    card: {
        marginTop: '16px',
    },
    cardHeader: {
        backgroundColor: '#1976d2', // custom blue color
        color: '#ffffff', // white color
    },
    cardContent: {
        backgroundColor: '#ffffff', // white color
    },
    responseMessage: {
        color: '#4caf50', // green color
    },
    errorMessage: {
        color: '#f44336', // red color
    },
    saveButton: {
        marginTop: '16px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

const EcwPatientForm: React.FC<EcwPatientFormProps> = ({ onPatientChange, patientContextID }) => {
    const [formData, setFormData] = useState<PatientData>({
        uid: null,
        ulname: '',
        ufname: '',
        uminitial: '',
        suffix: '',
        prefix: '',
        dob: '',
        sex: '',
        upPhone: '',
        umobileno: '',
        uemail: '',
        upaddress: '',
        upaddress2: '',
        upcity: '',
        upstate: '',
        zipcode: '',
        maritalstatus: 'Unknown',
        race: 'Declined to Specify',
        ethnicity: 'ASKU',
        doctorId: 0,
        refPrId: 0,
        rendPrId: 122,
        nostatements: 0,
    });

    const classes = useStyles();
    const [jsonInput, setJsonInput] = useState<string>('');
    const [selectedPatientRow, setSelectedPatientRow] = useState<any>(null);
    const [selectedPatientId, setSelectedPatientId] = useState<number | null>(null);
   // const [currentPatient, setCurrentPatient] = useState<{ id: string; name: string; dob: string } | undefined>(patientContext);
    const [isFormExpanded, setIsFormExpanded] = useState<boolean>(!selectedPatientId);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [npi, setNpi] = useState('');
    const [npiData, setNpiData] = useState<any>(null);
    const [responseMessage, setResponseMessage] = useState<string | null>(null);
    const [responseStatus, setResponseStatus] = useState<'success' | 'error' | null>(null);
    const [selectedPcp, setSelectedPcp] = useState<any|undefined|null>(undefined);
    const [selectedRefDoc, setSelectedRefDoc] = useState<any|undefined|null>(undefined);

    const { patientList, addPatientRow, getNewPatients, refreshPatients } = usePatient();


    useEffect(() => {
        if (patientContextID) {
            setSelectedPatientId(patientContextID);
            setIsFormExpanded(false);
        }
    }, [patientContextID]);

    const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleJsonInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setJsonInput(event.target.value);
    };

    const handleJsonPaste = () => {
        try {
            const parsedData = JSON.parse(jsonInput);

            if (parsedData?.patientInfo) {
                const patientInfo = parsedData.patientInfo;
                const ekgReports = parsedData.ekgReports || [];

                // Helper function to convert a date to YYYY-MM-DD format
                const formatDateToYYYYMMDD = (date: string) => {
                    const dateObj = new Date(`${date}T00:00:00Z`); // Force UTC interpretation
                    if (isNaN(dateObj.getTime())) return ''; // Invalid date
                    const year = dateObj.getUTCFullYear();
                    const month = (`0${dateObj.getUTCMonth() + 1}`).slice(-2);
                    const day = (`0${dateObj.getUTCDate()}`).slice(-2);
                    return `${year}-${month}-${day}`;
                };

                // Helper function to map race values
                const mapRaceValue = (value: string) => {
                    const raceMapping: { [key: string]: string } = {
                        'white': 'White',
                        'caucasian': 'White',
                        'white or caucasian' : 'White',
                        'black': 'Black or African American',
                        'african american': 'Black or African American',
                        'asian': 'Asian',
                        'native american': 'American Indian or Alaska Native',
                        'hispanic': 'Hispanic',
                        'pacific islander': 'Native Hawaiian or Other Pacific Islander',
                        'declined': 'Declined to Specify',
                        'refused': 'Refused to Report'
                    };
                    return raceMapping[value.toLowerCase()] || 'Declined to Specify';
                };

                // Helper function to map ethnicity values
                const mapEthnicityValue = (value: string) => {
                    const ethnicityMapping: { [key: string]: string } = {
                        'not hispanic or latino': '2186-5',
                        'hispanic or latino': '2135-2',
                        'declined': 'ASKU',
                        'refused': '2145-2'
                    };
                    return ethnicityMapping[value.toLowerCase()] || 'ASKU';
                };

                // Helper function to map marital status values
                const mapMaritalStatusValue = (value: string) => {
                    const maritalMapping: { [key: string]: string } = {
                        'married': 'Married',
                        'single': 'Single',
                        'widowed': 'Widowed',
                        'divorced': 'Divorced',
                        'partner': 'Partner',
                        'unknown': 'Unknown'
                    };
                    return maritalMapping[value.toLowerCase()] || 'Unknown';
                };

                // Find the oldest date in ekgReports
                const getOldestDate = (reports: { dateOfService: string }[]) => {
                    if (!reports || reports.length === 0) return ''; // Handle empty array
                    const dates = reports
                        .map(report => new Date(report.dateOfService)) // Convert dates to Date objects
                        .filter(date => !isNaN(date.getTime())); // Filter out invalid dates
                    if (dates.length === 0) return ''; // Handle case where no valid dates exist
                    const oldestDate = new Date(Math.min(...dates.map(date => date.getTime()))); // Find the oldest date
                    return formatDateToYYYYMMDD(oldestDate.toISOString()); // Format to YYYY-MM-DD
                };

                const mapGenderValue = (value: string) => {
                    const genderMapping: { [key: string]: string } = {
                        'male': 'male',
                        'm': 'male',
                        'female': 'female',
                        'f': 'female'
                    };
                    return genderMapping[value.toLowerCase()] || '';
                };

                setFormData((prevData) => ({
                    ...prevData,
                    uid : null,
                    ulname: patientInfo.lastName || '',
                    ufname: patientInfo.firstName || '',
                    uminitial: patientInfo.middleInitial || '',
                    suffix: patientInfo.suffix || '',
                    dob: formatDateToYYYYMMDD(patientInfo.dateOfBirth || ''),
                    sex: mapGenderValue(patientInfo.gender || '') ,
                    upPhone: patientInfo.homePhone || '',
                    umobileno: patientInfo.mobilePhone || '',
                    uemail: patientInfo.email || '',
                    upaddress: patientInfo.addressLine1 || '',
                    upaddress2: patientInfo.addressLine2 || '',
                    upcity: patientInfo.city || '',
                    upstate: patientInfo.state || '',
                    zipcode: patientInfo.zipcode || '',
                    maritalstatus: mapMaritalStatusValue(patientInfo.maritalStatus || ''),
                    race: mapRaceValue(patientInfo.race || ''),
                    ethnicity: mapEthnicityValue(patientInfo.ethnicity || ''),
                }));
            } else {
                alert('Invalid JSON format');
            }
        } catch (error) {
            alert('Failed to parse JSON');
        }
    };

    const clearForm = () => {
        setFormData({
            uid: null,
            ulname: '',
            ufname: '',
            uminitial: '',
            suffix: '',
            prefix: '',
            dob: '',
            sex: '',
            upPhone: '',
            umobileno: '',
            uemail: '',
            upaddress: '',
            upaddress2: '',
            upcity: '',
            upstate: '',
            zipcode: '',
            maritalstatus: 'Unknown',
            race: 'Declined to Specify',
            ethnicity: 'ASKU',
            doctorId: 0,
            refPrId: 0,
            rendPrId: 122,
            nostatements: 0,
        });
        setJsonInput('');
        setResponseMessage(null);
        setSelectedPatientId(null);
        onPatientChange(null);
        setSelectedPcp(null);
        setSelectedRefDoc(null);
    };

    const clearSelectedPatient = () => {
        setSelectedPatientId(null);
        onPatientChange(null);
        setIsFormExpanded(true);
        setSelectedPcp(null);
        setSelectedRefDoc(null);
    };

    const showSelectedPatientInForm = (patientRow: any) => {
        try {
            console.log("Select Patient for form: ", patientRow);
            if (patientRow) {

                // Helper function to convert a date to YYYY-MM-DD format
                const formatDateToYYYYMMDD = (date: string) => {
                    const dateObj = new Date(`${date}T00:00:00Z`); // Force UTC interpretation
                    if (isNaN(dateObj.getTime())) return ''; // Invalid date
                    const year = dateObj.getUTCFullYear();
                    const month = (`0${dateObj.getUTCMonth() + 1}`).slice(-2);
                    const day = (`0${dateObj.getUTCDate()}`).slice(-2);
                    return `${year}-${month}-${day}`;
                };

                // Helper function to map race values
                const mapRaceValue = (value: string) => {
                    const raceMapping: { [key: string]: string } = {
                        'white': 'White',
                        'caucasian': 'White',
                        'white or caucasian' : 'White',
                        'black': 'Black or African American',
                        'african american': 'Black or African American',
                        'asian': 'Asian',
                        'native american': 'American Indian or Alaska Native',
                        'hispanic': 'Hispanic',
                        'pacific islander': 'Native Hawaiian or Other Pacific Islander',
                        'declined': 'Declined to Specify',
                        'refused': 'Refused to Report'
                    };
                    return raceMapping[value.toLowerCase()] || 'Declined to Specify';
                };

                // Helper function to map ethnicity values
                const mapEthnicityValue = (value: string) => {
                    const ethnicityMapping: { [key: string]: string } = {
                        'not hispanic or latino': '2186-5',
                        'hispanic or latino': '2135-2',
                        'declined': 'ASKU',
                        'refused': '2145-2'
                    };
                    return ethnicityMapping[value.toLowerCase()] || 'ASKU';
                };

                // Helper function to map marital status values
                const mapMaritalStatusValue = (value: string) => {
                    const maritalMapping: { [key: string]: string } = {
                        'married': 'Married',
                        'single': 'Single',
                        'widowed': 'Widowed',
                        'divorced': 'Divorced',
                        'partner': 'Partner',
                        'unknown': 'Unknown'
                    };
                    return maritalMapping[value.toLowerCase()] || 'Unknown';
                };

                const mapGenderValue = (value: string) => {
                    const genderMapping: { [key: string]: string } = {
                        'male': 'male',
                        'm': 'male',
                        'female': 'female',
                        'f': 'female'
                    };
                    return genderMapping[value.toLowerCase()] || '';
                };

                setFormData((prevData) => ({
                    ...prevData,
                    uid : patientRow.PatientId,
                    ulname: patientRow.ulname || '',
                    ufname: patientRow.ufname || '',
                    uminitial: patientRow.uminitial || '',
                    suffix: patientRow.suffix || '',
                    dob: formatDateToYYYYMMDD(patientRow.DOB || ''),
                    sex: mapGenderValue(patientRow.sex || '') ,
                    upPhone: patientRow.upphone || '',
                    umobileno: patientRow.umobileno || '',
                    uemail: patientRow.uemail || '',
                    upaddress: patientRow.upaddress || '',
                    upaddress2: patientRow.upaddress2 || '',
                    upcity: patientRow.upcity || '',
                    upstate: patientRow.upstate || '',
                    zipcode: patientRow.zipcode || '',
                    maritalstatus: mapMaritalStatusValue(patientRow.maritalStatus || ''),
                    race: mapRaceValue(patientRow.race || ''),
                    ethnicity: mapEthnicityValue(patientRow.ethnicity || ''),
                }));
            }
        } catch (error) {
            alert('Failed to parse JSON');
        }
    };

    const handlePatientSelection = (patient: any) => {
        setSelectedPatientRow(patient || null);
        onPatientChange(patient|| null);
        showSelectedPatientInForm(patient);
        if (patient) {
            setSelectedPatientId(patient.PatientId);
            setIsFormExpanded(false);
        }
    };

    const getScalarFromResultSet = (response: AxiosResponse<any, any>) => {
        if (response.data) {
            const dframe0: string = response.data.frame0;
            const myObj0: any = dframe0 && JSON.parse(dframe0);
            const gridData0: any[] = myObj0 && myObj0.rows;
            const patid = gridData0 && gridData0.length > 0 ? gridData0[0].patid : null;

            //const dframe1: string = response.data.frame1;
            //const myObj1: any = dframe1 && JSON.parse(dframe1);
            //const gridData1: any[] = myObj1 && myObj1.rows;

            //const apexEncounterId = gridData0 && gridData0.length > 0 ? gridData0[0].apex_encounter_id : null;

            return {patid};
        }
        return null;
    };

    const handleSavePatient = async () => {
        try {
            const apiURL = apiConfig.REACT_APEX_BASE_API;
            const authHeader = JSON.parse(localStorage.getItem('authHeader') || '{}');
            const urlPrefix = `${apiURL}/exsql?dbserver=`;
            const dataURL = `apex&sqltype=customSQL&sqltext= declare @patid int set @patid = ${formData.uid} exec apex.rc.mnt_new_patient 
            @uid = @patid output ,
            @ulname = '${formData.ulname}',
            @ufname = '${formData.ufname}',
            @uminitial = '${formData.uminitial}',
            @suffix = '${formData.suffix}',
            @prefix = '${formData.prefix}',
            @dob = '${formData.dob}',
            @sex = '${formData.sex}',
            @upPhone = '${formData.upPhone}',
            @umobileno = '${formData.umobileno}',
            @uemail = '${formData.uemail}',
            @maritalstatus = '${formData.maritalstatus}',
            @race = '${formData.race}',
            @ethnicity = '${formData.ethnicity}',
            @upaddress = '${formData.upaddress}',
            @upaddress2 = '${formData.upaddress2}',
            @upcity = '${formData.upcity}',
            @upstate = '${formData.upstate}',
            @zipcode = '${formData.zipcode}',
            @doctorId = ${selectedPcp ? selectedPcp.doctorid : 32320},
            @refPrId = ${selectedRefDoc ? selectedRefDoc.doctorid : 122},
            @rendPrId = ${formData.rendPrId},
            @nostatements = ${formData.nostatements};

            select @patid as patid
            `;

            console.log("Saving Patient:", dataURL);

            const response = await axios.get(urlPrefix + dataURL, {headers: authHeader});

            if (!response.data) {
                setResponseMessage('Failed to save patient.');
                setResponseStatus('error');
            } else {
                const result = getScalarFromResultSet(response);
                if (result) {
                    if (result.patid) {

                        refreshPatients(result.patid);
                        setSelectedPatientId(result.patid);
                        const newPatient = {
                            id: result.patid,
                            PatientId: result.patid,
                            patid: result.patid,
                            name: `${formData.ufname} ${formData.ulname}`,
                            dob: formData.dob,
                        };
                        onPatientChange(newPatient);
                    }
                    //setEncounterId(result.apexEncounterId);
                    setResponseMessage(`Patient ID: ${result.patid}`);
                    setResponseStatus('success');
                } else {
                    setResponseMessage('Failed to save patient.');
                    setResponseStatus('error');
                }
            }
        } catch (error: any) {
            setResponseMessage(error.message || 'Failed to save patient.');
            setResponseStatus('error');
        }
    };


    const handleCreatePatient0 = async () => {
        try {
            const response = await axios.post('/api/patients', formData);
            const patientId = response.data.patientId;
            setSelectedPatientId(patientId);
            onPatientChange(response.data);
            const newPatient = {
                id: patientId,
                name: `${formData.ufname} ${formData.ulname}`,
                dob: formData.dob,
            };
            onPatientChange(newPatient);
            setResponseMessage(`Patient created successfully with ID: ${patientId}`);
            setIsFormExpanded(false);
        } catch (error) {
            setResponseMessage('Failed to create patient. Please try again.');
            console.error(error);
        }
    };

    const toggleFormExpansion = () => {
        setIsFormExpanded((prev) => !prev);
    };

    const getLocationByZipCode = async (zipCode: string) => {
        const url = `https://api.zippopotam.us/us/${zipCode}`;
        try {
            const response = await axios.get(url);
            const city = response.data.places[0]['place name'];
            const state = response.data.places[0]['state abbreviation'];
            setFormData((prevData) => ({
                ...prevData,
                upcity: city,
                upstate: state,
            }));
        } catch (error) {
            console.error('Failed to fetch location data:', error);
        }
    };

    const handleZipCodeBlur = () => {
        if (formData.zipcode.length === 5) {
            getLocationByZipCode(formData.zipcode);
        }
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const ethnicityLabelMapping: { [key: string]: string } = {
        "ASKU": "Declined to Specify",
        "2135-2": "Hispanic or Latino",
        "2186-5": "Not Hispanic or Latino",
        "2145-2": "Refused to Report"
    };

    const formatDate = (dateString: string) => {
        if (!dateString) return '';
        const dateObj = new Date(`${dateString}T00:00:00Z`); // Force UTC interpretation
        if (isNaN(dateObj.getTime())) return ''; // Invalid date
        const year = dateObj.getUTCFullYear();
        const month = (`0${dateObj.getUTCMonth() + 1}`).slice(-2);
        const day = (`0${dateObj.getUTCDate()}`).slice(-2);
        return `${year}-${month}-${day}`;
    };

    const handleSelectedPcp = (pcpselected: any) => {
        console.log("Selected PCP: ", pcpselected);
        setSelectedPcp(pcpselected);
    }

    const handleSelectedRefDoc = (pcpselected: any) => {
        console.log("Selected RefDoc: ", pcpselected);
        setSelectedRefDoc(pcpselected);
    }

    return (
        <Box  style={{ padding: 16 }}>
            <Box display="flex" flexDirection="row" >
            <SelectEcwPatient selectedEcwPatientID={selectedPatientId} setSelectedEcwPatient={handlePatientSelection} />
            {selectedPatientId && (
                <IconButton onClick={clearSelectedPatient}>
                    <ClearIcon />
                </IconButton>
            )}
            {selectedPatientRow ? (
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="body1">
                        {`${selectedPatientRow.Name && selectedPatientRow.Name.split(', ')[1]} ${selectedPatientRow.Name && selectedPatientRow.Name.split(', ')[0]} [${selectedPatientRow.PatientId}][${formatDate(selectedPatientRow.DOB)}] `}
                    </Typography>
                    <IconButton onClick={toggleFormExpansion}>
                        {isFormExpanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </Box>
            ) : ( <IconButton onClick={toggleFormExpansion}>
                {isFormExpanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton> )}
                { isFormExpanded ? (

                        <Box mt={2} display="flex" justifyContent="space-between">
                            <Button
                                onClick={clearForm}
                                color="secondary"
                                variant="contained"
                            >
                                Clear Form
                            </Button>
                            <Button
                                onClick={handleSavePatient}
                                color="primary"
                                variant="contained"
                            >
                                Save Patient
                            </Button>
                        </Box>
) : null
                }
                <Box display="flex" alignItems="center" gap={0.5}>
                    <SelectEcwPcp selectedPcp={selectedPcp} setSelectedPcp={handleSelectedPcp} />
                    {selectedPcp && (
                        <IconButton onClick={() => setSelectedPcp(null)}>
                            <ClearIcon />
                        </IconButton>
                    )}
                </Box>
                <Box display="flex" alignItems="center" gap={0.5}>
                    <SelectEcwPcp selectedPcp={selectedRefDoc} setSelectedPcp={handleSelectedRefDoc} />
                    {selectedPcp && (
                        <IconButton onClick={() => setSelectedRefDoc(null)}>
                            <ClearIcon />
                        </IconButton>
                    )}
                </Box>

            </Box>

            <Collapse in={isFormExpanded} timeout="auto" unmountOnExit>
                <form id="new-patient-form">
                    <Box display="flex" flexDirection="column" gap={2}>
                        <Box className={classes.formFieldRow}>
                            <TextField
                                label="Last Name"
                                name="ulname"
                                variant="outlined"
                                fullWidth
                                value={formData.ulname}
                                onChange={handleTextFieldChange}
                                size="small"
                            />
                            <TextField
                                label="First Name"
                                name="ufname"
                                variant="outlined"
                                fullWidth
                                value={formData.ufname}
                                onChange={handleTextFieldChange}
                                size="small"
                            />
                            <TextField
                                label="Middle Initial"
                                name="uminitial"
                                variant="outlined"
                                fullWidth
                                value={formData.uminitial}
                                onChange={handleTextFieldChange}
                                size="small"
                            />
                            <Box className={classes.formFieldRow}>
                                <FormControl component="fieldset" style={{ flex: 1 }}>
                                    <RadioGroup
                                        name="sex"
                                        value={formData.sex}
                                        onChange={handleRadioChange}
                                        row
                                    >
                                        <FormControlLabel value="male" control={<Radio />} label="M" />
                                        <FormControlLabel value="female" control={<Radio />} label="F" />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                            <TextField
                                label="Date of Birth"
                                name="dob"
                                variant="outlined"
                                fullWidth
                                value={formData.dob}
                                onChange={handleTextFieldChange}
                                placeholder="MM-DD-YYYY"
                                size="small"
                            />
                        </Box>
                        <Box display="flex" gap={2}>
                            <TextField
                                label="Phone"
                                name="upPhone"
                                variant="outlined"
                                fullWidth
                                value={formData.upPhone}
                                onChange={handleTextFieldChange}
                                size="small"
                            />
                            <TextField
                                label="Mobile Number"
                                name="umobileno"
                                variant="outlined"
                                fullWidth
                                value={formData.umobileno}
                                onChange={handleTextFieldChange}
                                size="small"
                            />
                            <TextField
                                label="Email"
                                name="uemail"
                                variant="outlined"
                                fullWidth
                                value={formData.uemail}
                                onChange={handleTextFieldChange}
                                size="small"
                            />
                        </Box>
                        <Box display="flex" gap={2}>
                            <TextField
                                label="Address"
                                name="upaddress"
                                variant="outlined"
                                fullWidth
                                value={formData.upaddress}
                                onChange={handleTextFieldChange}
                                size="small"
                            />
                            <TextField
                                label="Address Line 2"
                                name="upaddress2"
                                variant="outlined"
                                fullWidth
                                value={formData.upaddress2}
                                onChange={handleTextFieldChange}
                                size="small"
                            />
                        </Box>
                        <Box display="flex" gap={2}>
                            <TextField
                                label="City"
                                name="upcity"
                                variant="outlined"
                                fullWidth
                                value={formData.upcity}
                                onChange={handleTextFieldChange}
                                size="small"
                                InputProps={{ readOnly: true }}
                            />
                            <TextField
                                label="State"
                                name="upstate"
                                variant="outlined"
                                fullWidth
                                value={formData.upstate}
                                onChange={handleTextFieldChange}
                                size="small"
                                InputProps={{ readOnly: true }}
                            />
                            <TextField
                                label="Zip Code"
                                name="zipcode"
                                variant="outlined"
                                fullWidth
                                value={formData.zipcode}
                                onChange={handleTextFieldChange}
                                onBlur={handleZipCodeBlur}
                                size="small"
                            />
                        </Box>
                        <Box style={{ marginBottom: '8px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap', gap: '16px' }}>
                            <Typography variant="subtitle1"><strong>Marital Status:</strong> {formData.maritalstatus || 'N/A'}</Typography>
                            <Typography variant="subtitle1"><strong>Race:</strong> {formData.race || 'N/A'}</Typography>
                            <Typography variant="subtitle1"><strong>Ethnicity:</strong> {ethnicityLabelMapping[formData.ethnicity || ''] || 'N/A'}</Typography>
                        </Box>
                        <Card className={classes.card}>
                            <CardHeader
                                title="Additional Information"
                                action={
                                    <IconButton onClick={handleExpandClick}>
                                        {expanded ? <ExpandLess /> : <ExpandMore />}
                                    </IconButton>
                                }
                                className={classes.cardHeader}
                                onClick={handleExpandClick}
                            />
                            <Collapse in={expanded} timeout="auto" unmountOnExit>
                                <CardContent className={classes.cardContent}>
                                    <Box className={classes.formFieldRow}>
                                        <FormControl component="fieldset" style={{ flex: 1 }}>
                                            <FormLabel component="legend">Marital Status</FormLabel>
                                            <RadioGroup
                                                name="maritalstatus"
                                                value={formData.maritalstatus || ''}
                                                onChange={handleRadioChange}
                                                row
                                            >
                                                {['Unknown', 'Married', 'Single', 'Widowed', 'Divorced', 'Partner'].map(option => (
                                                    <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                        <FormControl component="fieldset" style={{ flex: 1 }}>
                                            <FormLabel component="legend">Race</FormLabel>
                                            <RadioGroup
                                                name="race"
                                                value={formData.race || ''}
                                                onChange={handleRadioChange}
                                                row
                                            >
                                                {['Declined to Specify', 'White', 'Black', 'African American', 'American Indian or Alaska Native', 'Asian', 'Asian Indian', 'Black or African American', 'English', 'Hispanic', 'Native Hawaiian', 'Native Hawaiian or Other Pacific Islander', 'Other Pacific Islander', 'Other Race', 'Refused to Report', 'Spanish American Indian', 'Vietnamese'].map(option => (
                                                    <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                        <FormControl component="fieldset" style={{ flex: 1 }}>
                                            <FormLabel component="legend">Ethnicity</FormLabel>
                                            <RadioGroup
                                                name="ethnicity"
                                                value={formData.ethnicity || ''}
                                                onChange={handleRadioChange}
                                                row
                                            >
                                                <FormControlLabel value="ASKU" control={<Radio />} label="Declined to Specify" />
                                                <FormControlLabel value="2135-2" control={<Radio />} label="Hispanic or Latino" />
                                                <FormControlLabel value="2186-5" control={<Radio />} label="Not Hispanic or Latino" />
                                                <FormControlLabel value="2145-2" control={<Radio />} label="Refused to Report" />
                                            </RadioGroup>
                                        </FormControl>
                                        <Box className={classes.formFieldRow}>
                                            <TextField
                                                label="Suffix"
                                                name="suffix"
                                                variant="outlined"
                                                fullWidth
                                                value={formData.suffix}
                                                onChange={handleTextFieldChange}
                                                size="small"
                                            />
                                            <TextField
                                                label="Prefix"
                                                name="prefix"
                                                variant="outlined"
                                                fullWidth
                                                value={formData.prefix}
                                                onChange={handleTextFieldChange}
                                                size="small"
                                            />

                                        </Box>
                                    </Box>
                                </CardContent>
                            </Collapse>
                        </Card>
                        <Box display="flex" flexDirection="row">
                        <TextField
                            label="Paste JSON Here"
                            value={jsonInput}
                            onChange={handleJsonInputChange}
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                        />
                        <Button
                            onClick={handleJsonPaste}
                            color="primary"
                            variant="contained"
                            style={{ marginRight: 'auto' }}
                        >
                            Populate Fields
                        </Button>
                        </Box>

                        {responseMessage && (
                            <Typography variant="body1" color={responseMessage.includes('successfully') ? 'green' : 'error'}>
                                {responseMessage}
                            </Typography>
                        )}
                    </Box>
                </form>
            </Collapse>
        </Box>
    );
};

export default EcwPatientForm;
