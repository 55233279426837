import React, { useState, useEffect, useRef } from 'react';
import {
    Popper,
    Paper,
    Typography,
    Box,
    Button,
    TextField,
    ClickAwayListener,
} from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import Draggable from 'react-draggable';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import apiConfig from '../apiConfig';
import CreateBillingTempPatient from './CreateBillingTempPatient';
import { usePatient } from '../Contexts/PatientContext';
import EcwAddNewPatient from "../Features/deposits/EcwAddNewPatient";

interface SelectEcwPatientProps {
    selectedEcwPatientID: any;
    setSelectedEcwPatient: (patient: any) => void;
}

function DraggablePaperComponent(props: any) {
    return (
        <Draggable handle="#draggable-popper-title">
            <Paper elevation={3} {...props} />
        </Draggable>
    );
}

export const SelectEcwPatient: React.FC<SelectEcwPatientProps> = ({ selectedEcwPatientID, setSelectedEcwPatient }) => {
    const apiURL = apiConfig.REACT_APEX_BASE_API;
    const gridRef = useRef<any>(null);
    const initFocusRef = useRef<HTMLInputElement>(null);
    const anchorElRef = useRef<HTMLDivElement>(null);
    const [selectedOption, setSelectedOption] = useState<any>();
    const [currentSelectedPatient, setCurrentSelectedPatient] = useState<any>({});
    const [quickPatientFilter, setQuickPatientFilter] = useState('');
    const { patientList, addPatientRow, getNewPatients } = usePatient();
    const [isNewPatientModeOn, setIsNewPatientModeOn] = useState(false);
    const [open, setOpen] = useState(false);

    const [lastNameFilter, setLastNameFilter] = useState<string | null>(null);
    const [firstNameFilter, setFirstNameFilter] = useState<string | null>(null);

    const [openPatient, setOpenPatient] = useState<boolean>(false);
    const anchorRefPatient = useRef<HTMLButtonElement>(null);

    const handlePatientOpen = () => {
        setOpenPatient(true);
    };

    const handlePatientClose = () => {
        setOpenPatient(false);
    };

    useEffect(() => {
        if (selectedEcwPatientID) {
            const newOptionElement = patientList.find((row: { [x: string]: any; }) => row['PatientId'] === selectedEcwPatientID);
            const selectedPat = patientList.find((row: { [x: string]: any; }) => row['PatientId'] === selectedEcwPatientID);
            console.log("Selected pat, setting local var:", selectedEcwPatientID, selectedPat, newOptionElement);
            setCurrentSelectedPatient(selectedPat);
            setSelectedOption(newOptionElement);
        }else {
            console.log("Clearning Selected pat, :");
            clearSelectedPatient();
        }
    }, [selectedEcwPatientID, patientList]);

    useEffect(() => {
        if (open && initFocusRef.current) {
            initFocusRef.current.focus();
        }
    }, [open]);

    const handleQuickPatientFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setQuickPatientFilter(value);

        if (value.includes(',')) {
            const [lastName, firstName] = value.split(',').map(part => part.trim());
            setLastNameFilter(lastName.length >= 2 ? lastName : null);
            setFirstNameFilter(firstName || null);
        } else {
            setLastNameFilter(value.length >= 2 ? value : null);
            setFirstNameFilter(null);
        }

        if (gridRef.current) {
            gridRef.current.api.onFilterChanged();
        }
    };

    const isExternalFilterPresent = () => {
        return lastNameFilter !== null || firstNameFilter !== null;
    };

    const doesExternalFilterPass = (node: any) => {
        const { Name } = node.data;
        const [lastName, firstName] = Name.split(', ').map((part: string) => part.trim());

        const lastNameMatch = lastNameFilter ? lastName.toLowerCase().startsWith(lastNameFilter.toLowerCase()) : true;
        const firstNameMatch = firstNameFilter ? firstName.toLowerCase().startsWith(firstNameFilter.toLowerCase()) : true;

        return lastNameMatch && firstNameMatch;
    };



    const clearQuickPatientFilter = () => {
        setQuickPatientFilter('');
        if (gridRef.current) {
            gridRef.current.api.setQuickFilter('');
        }
    };

    const handleGridRowSelected = (event: any) => {
        const selectedNodes = event.api.getSelectedNodes().filter((node: any) => node.selected);
        const datarow = selectedNodes[0].data;
        setSelectedEcwPatient(datarow);
        setQuickPatientFilter(datarow.Name);
    };

    const onGridReady = (params: any) => {
        // Add any grid initialization code here
    };

    const onNewPatientAdded = (newPat: any) => {
        addPatientRow(newPat);
        setSelectedEcwPatient(newPat);
        setQuickPatientFilter(newPat.Name);
        setOpen(false);
    };

    const onToggerNewPatientMode = () => {
        setIsNewPatientModeOn(!isNewPatientModeOn);
    }

    const clearSelectedPatient = () => {
        setSelectedEcwPatient(null);
        setQuickPatientFilter('');
        setCurrentSelectedPatient(null);
    };

    const formatDate = (dateString: string) => {
        if (!dateString) return '';
        const dateObj = new Date(`${dateString}T00:00:00Z`); // Force UTC interpretation
        if (isNaN(dateObj.getTime())) return ''; // Invalid date
        const year = dateObj.getUTCFullYear();
        const month = (`0${dateObj.getUTCMonth() + 1}`).slice(-2);
        const day = (`0${dateObj.getUTCDate()}`).slice(-2);
        return `${year}-${month}-${day}`;
    };

    if (!patientList) return null;

    console.log("Selected Patient ID:", selectedEcwPatientID);
    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Box ref={anchorElRef}>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" border="1px solid" padding="8px" onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>
                    {currentSelectedPatient ? (
                        <Typography variant="body1" style={{ marginRight: 'auto' }}>
                            {`${currentSelectedPatient.Name && currentSelectedPatient.Name.split(', ')[1]} ${currentSelectedPatient.Name && currentSelectedPatient.Name.split(', ')[0]} [${currentSelectedPatient.PatientId}][${formatDate(currentSelectedPatient.DOB)}] `}
                        </Typography>
                    ) : (
                        <Typography variant="body1" style={{ marginRight: 'auto' }}>Patient...</Typography>
                    )}
                    {currentSelectedPatient && (
                        <Button size="small" onClick={(e) => { e.stopPropagation(); clearSelectedPatient(); }}>
                            X
                        </Button>
                    )}
                </Box>
                <Popper open={open} anchorEl={anchorElRef.current} placement="bottom" disablePortal style={{ zIndex: 1300 }}>
                    <DraggablePaperComponent style={{ width: 650 }}>
                        <Box p={2} display="flex" justifyContent="space-between" alignItems="center" style={{ cursor: 'move' }}>
                            <Typography variant="h6" id="draggable-popper-title">Select Patient (includes Temp Patients)</Typography>
                            <Button size="small" onClick={() => setOpen(false)}>X</Button>
                        </Box>
                        <Box p={2}>
                            <Box display="flex" alignItems="center" mb={2}>
                                <TextField
                                    value={quickPatientFilter}
                                    onChange={handleQuickPatientFilter}
                                    placeholder="Select Patient"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    InputProps={{
                                        endAdornment: quickPatientFilter && (
                                            <Button size="small" onClick={clearQuickPatientFilter}>
                                                X
                                            </Button>
                                        ),
                                    }}
                                    inputRef={initFocusRef}
                                />
                                <Button
                                    style={{ marginLeft: '8px', border: '2px solid green', minWidth: '40px' }}
                                    onClick={onToggerNewPatientMode}
                                >
                                    {isNewPatientModeOn ? '-' : '+'}
                                </Button>
                                <Button onClick={getNewPatients}>Refresh</Button>
                                <Button ref={anchorRefPatient} onClick={handlePatientOpen} variant="contained" color="primary">
                                    NewECWPat
                                </Button>
                                <EcwAddNewPatient open={openPatient} anchorEl={anchorRefPatient.current} onClose={handlePatientClose} />
                            </Box>

                            {isNewPatientModeOn ? (
                                <CreateBillingTempPatient onNewPatientAdded={onNewPatientAdded} />
                            ) : (
                                <Box className="ag-theme-alpine" style={{ width: '100%', height: '250px' }}>
                                    <AgGridReact
                                        ref={gridRef}
                                        onGridReady={onGridReady}
                                        rowData={patientList}
                                        rowHeight={20}
                                        columnDefs={[
                                            { field: 'src', filter: true, width: 75, sortable: true, cellStyle: { fontSize: '12px' }, headerName: 'Src', resizable: true, editable: true },
                                            { field: 'Name', filter: true, width: 180, sortable: true, cellStyle: { fontSize: '14px' }, headerName: 'Patient', resizable: true, editable: true },
                                            { field: 'lastname', filter: true, width: 180, sortable: true, cellStyle: { fontSize: '14px' }, headerName: 'lastname', resizable: true, editable: true },
                                            { field: 'firstname', filter: true, width: 180, sortable: true, cellStyle: { fontSize: '14px' }, headerName: 'firstname', resizable: true, editable: true },
                                            { field: 'DOB', width: 120, sortable: true, cellStyle: { fontSize: '12px' }, headerName: 'Birth', resizable: true },
                                            { field: 'mdate', width: 120, sortable: true, cellStyle: { fontSize: '12px' }, headerName: 'MDate', resizable: true },
                                            { field: 'PatientId', filter: true, width: 90, sortable: true, cellStyle: { fontSize: '14px' }, headerName: 'ID', resizable: true, sortingOrder: ['desc'] },
                                            { field: 'Comment', filter: true, width: 100, sortable: true, cellStyle: { fontSize: '12px' }, headerName: 'Comment', resizable: true },
                                        ]}
                                        animateRows={true}
                                        rowSelection="single"
                                        rowDragManaged={true}
                                        suppressMoveWhenRowDragging={true}
                                        onSelectionChanged={handleGridRowSelected}
                                        isExternalFilterPresent={isExternalFilterPresent}
                                        doesExternalFilterPass={doesExternalFilterPass}
                                    />
                                </Box>
                            )}
                        </Box>
                    </DraggablePaperComponent>
                </Popper>
            </Box>
        </ClickAwayListener>
    );
};

export default SelectEcwPatient;
