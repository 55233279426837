import React, { useState } from 'react';
import Page from "../../Components/splitpage/page";
import LoadMerchantTransactions from './LoadMerchantTransactions';
import LoadBankTransactions from './LoadBankTransactions';
import ReviewByClaim from './ReviewByClaim';
import ReviewByPayment from './ReviewByPayment';
import ReviewByPatient from './ReviewByPatient';
import PaymentDepositsPage from "./PaymentDepositsPage";
import ReviewVirtualCards from "./ReviewVirtualCards";
import ERAParseComponent from "./ERAParseComponent";
import ERAReviewPage from "./ERAReviewPage";
import BaylorEKGEntryPage from "./BaylorEKGEntryPage";
import EchoClaimEntryPage from "./EchoClaimEntryPage";

import { Button, Box } from '@mui/material';
import TestEcwPatientForm from "./TestEcwPatientForm";

const PaymentsPage: React.FC = () => {
    const [activeComponent, setActiveComponent] = useState<React.ReactNode>(null);

    const loadMerchantTransactions = () => {
        setActiveComponent(<LoadMerchantTransactions />);
    };

    const loadVirtualCardransactions = () => {
        setActiveComponent(<ReviewVirtualCards />);
    };


    const loadBankTransactions = () => {
        setActiveComponent(<LoadBankTransactions />);
    };

    const reviewByClaim = () => {
        setActiveComponent(<ReviewByClaim />);
    };

    const reviewByPayment = () => {
        setActiveComponent(<ReviewByPayment />);
    };

    const reviewByPatient = () => {
        setActiveComponent(<ReviewByPatient />);
    };

    const paymentsDepositView = () => {
        setActiveComponent(<PaymentDepositsPage />);
    };

    const eraReviewPageView = () => {
        setActiveComponent(<ERAReviewPage />);
    };

    const balorEkgEntryPageView = () => {
        setActiveComponent(<BaylorEKGEntryPage />);
    };

    const echoClaimEntryPageView = () => {
        setActiveComponent(<EchoClaimEntryPage />);
    };

    const testEcwPatientFormView = () => {
        setActiveComponent(<TestEcwPatientForm />);
    };

    return (
        <Page
            actionButtons={
                <Box>
                    <ERAParseComponent />
                    <Button onClick={eraReviewPageView} style={{ marginRight: '10px' }}>ERAs</Button>
                    <Button onClick={balorEkgEntryPageView} style={{ marginRight: '10px' }}>Baylor EKGs</Button>
                    <Button onClick={echoClaimEntryPageView} style={{ marginRight: '10px' }}>ECHO Claims</Button>
                    <Button onClick={testEcwPatientFormView} style={{ marginRight: '10px' }}>Test PatForm</Button>
                    <Button onClick={paymentsDepositView} style={{ marginRight: '10px' }}>Deposits</Button>
                    <Button onClick={loadMerchantTransactions} style={{ marginRight: '10px' }}>Load Merchant</Button>
                    <Button onClick={loadBankTransactions} style={{ marginRight: '10px' }}>Load Bank</Button>
                    <Button onClick={reviewByClaim} style={{ marginRight: '10px' }}>By Claim</Button>
                    <Button onClick={reviewByPayment} style={{ marginRight: '10px' }}>By Payment</Button>
                    <Button onClick={reviewByPatient} style={{ marginRight: '10px' }}>By Patient</Button>
                    <Button onClick={loadVirtualCardransactions} style={{ marginRight: '10px' }}>Virtual Cards</Button>

                </Box>
            }
            content={
                <>
                    {activeComponent}
                </>
            }
            title="Payments Page"
        />
    );
};

export default PaymentsPage;
