import React, { useState, useRef, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Box, VStack, Button, HStack, Input, Checkbox } from "@chakra-ui/react";
import axios from "axios";
import apiConfig from "../apiConfig";
import {Typography} from "@mui/material";

interface Diagnosis {
    icdcode: string;
    icdname: string;
}

interface SelectClaimDiagnosisProps {
    patientId?: number;
    selectedDiagnosis: (dxString: string) => void;
}

const SelectClaimDiagnosis: React.FC<SelectClaimDiagnosisProps> = ({ patientId, selectedDiagnosis }) => {
    const apexICDGridRef = useRef<AgGridReact<Diagnosis> | null>(null);
    const claimsICDGridRef = useRef<AgGridReact<Diagnosis> | null>(null);
    const [diagCodesSourceList, setDiagCodesSourceList] = useState<Diagnosis[]>([]);
    const [selectedDx, setSelectedDx] = useState<Diagnosis[]>([]);
    const [quickFilter, setQuickFilter] = useState("");
    const [patientPrevDiagCodesList, setPatientPrevDiagCodesList] = useState<Diagnosis[]>([]);
    const [diagCodesList, setDiagCodesList] = useState<Diagnosis[]>([]);
    const [selectedDxString, setSelectedDxString] = useState<string>('');
    const [showPrevDx, setShowPrevDx] = useState(false);

    let apiURL = apiConfig.REACT_APEX_BASE_API;

    const refreshIcdRefData = async () => {
        const urlPrefix = apiURL + '/exsql?dbserver=';
        const dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0 select * from apex.rc.billing_icditemref order by freqcount desc`;

        const res2 = await axios.get(urlPrefix + dataURL);
        if (res2.data) {
            const gridData = JSON.parse(res2.data.frame0).rows;
            setDiagCodesList(gridData);
            setDiagCodesSourceList(gridData);
        }
    };

    const getPatientPrevIcdData = async (patientId: number) => {
        const urlPrefix = apiURL + '/exsql?dbserver=';
        const dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0 select * from rc.billing_pat_icditemref where patientid = ${patientId} order by enc_date desc`;

        const res2 = await axios.get(urlPrefix + dataURL);
        if (res2.data) {
            const gridData = JSON.parse(res2.data.frame0).rows;
            setPatientPrevDiagCodesList(gridData);
        }
    };

    useEffect(() => {
        refreshIcdRefData();
    }, []);

    useEffect(() => {
        if (patientId) {
            getPatientPrevIcdData(patientId);
        }
    }, [patientId]);

    useEffect(() => {
        setDiagCodesSourceList(showPrevDx ? patientPrevDiagCodesList : diagCodesList);
    }, [showPrevDx]);

    const handleAddDx = () => {
        const selectedNodes = apexICDGridRef.current?.api?.getSelectedNodes();
        const selectedDxCodes = selectedNodes?.map((node) => node.data).filter(Boolean) as Diagnosis[];

        setSelectedDx((prev) => [...prev, ...selectedDxCodes]);
        apexICDGridRef.current?.api?.deselectAll();
    };

    const handleRemoveDx = () => {
        const selectedNodes = claimsICDGridRef.current?.api?.getSelectedNodes();
        const selectedDxCodes = selectedNodes?.map((node) => node.data?.icdcode).filter((code): code is string => code !== undefined) ?? [];

        setSelectedDx((prev) => prev.filter((dx) => !selectedDxCodes.includes(dx.icdcode)));
    };

    const moveDxUp = () => {
        const selectedNode = claimsICDGridRef.current?.api?.getSelectedNodes()[0];
        if (!selectedNode || selectedNode.rowIndex === null || selectedNode.rowIndex === 0) return;

        const index = selectedNode.rowIndex;
        setSelectedDx((prev) => {
            const newDx = [...prev];
            [newDx[index - 1], newDx[index]] = [newDx[index], newDx[index - 1]];
            return newDx;
        });
    };

    const moveDxDown = () => {
        const selectedNode = claimsICDGridRef.current?.api?.getSelectedNodes()[0];
        if (!selectedNode || selectedNode.rowIndex === null || selectedNode.rowIndex === selectedDx.length - 1) return;

        const index = selectedNode.rowIndex;
        setSelectedDx((prev) => {
            const newDx = [...prev];
            [newDx[index + 1], newDx[index]] = [newDx[index], newDx[index + 1]];
            return newDx;
        });
    };

    const handleQuickFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        const filterText = event.target.value;
        setQuickFilter(filterText);
        apexICDGridRef.current?.api?.setQuickFilter(filterText);
    };

    useEffect(() => {
        const dxString = selectedDx.map((dx) => dx.icdcode).join(",");
        setSelectedDxString(dxString);
        selectedDiagnosis(dxString);
    }, [selectedDx, selectedDiagnosis]);

    return (
        <HStack spacing={4} align="start">
            {/* Left Grid */}
            <VStack width="45%">
                <HStack width="100%">
                    <Checkbox isChecked={showPrevDx} onChange={() => setShowPrevDx(!showPrevDx)}>
                        PrevDX
                    </Checkbox>
                    <Input placeholder="Quick Filter" value={quickFilter} onChange={handleQuickFilter} />
                </HStack>
                <Box className="ag-theme-alpine" style={{ height: "300px", width: "100%" }}>
                    <AgGridReact
                        ref={apexICDGridRef}
                        columnDefs={[
                            { headerName: "ICD", field: "icdcode", width: 100 },
                            { headerName: "Description", field: "icdname", width: 250 },
                        ]}
                        rowData={diagCodesSourceList}
                        rowSelection="multiple"
                    />
                </Box>
            </VStack>

            {/* Buttons Column */}
            <VStack spacing={2} justify="space-between" height="100%" align="center">
                <VStack spacing={2}>
                    <Button onClick={handleAddDx} colorScheme="blue" isDisabled={diagCodesSourceList.length === 0}>
                        &rarr;
                    </Button>
                    <Button onClick={handleRemoveDx} colorScheme="red" isDisabled={selectedDx.length === 0}>
                        &larr;
                    </Button>
                </VStack>
                <VStack spacing={2}>
                    <Button onClick={moveDxUp} isDisabled={selectedDx.length === 0} colorScheme="teal">
                        Up
                    </Button>
                    <Button onClick={moveDxDown} isDisabled={selectedDx.length === 0} colorScheme="teal">
                        Down
                    </Button>
                </VStack>
            </VStack>

            {/* Right Grid */}
            <VStack width="45%">
                <HStack width="100%">
                    <Typography > {selectedDxString}  </Typography>
                </HStack>
                <Box className="ag-theme-alpine" style={{ height: "300px", width: "100%" }}>
                    <AgGridReact
                        ref={claimsICDGridRef}
                        columnDefs={[
                            {
                                headerName: "Nr",
                                valueGetter: (params) =>
                                    params.node?.rowIndex !== null && params.node?.rowIndex !== undefined
                                        ? params.node.rowIndex + 1
                                        : "",
                                width: 50,
                            },
                            { headerName: "ICD", field: "icdcode", width: 100 },
                            { headerName: "Description", field: "icdname", width: 250 },
                        ]}
                        rowData={selectedDx}
                        rowSelection="single"
                    />
                </Box>
            </VStack>
        </HStack>
    );
};

export default SelectClaimDiagnosis;
