
import React, { useEffect } from "react";
import useState from 'react-usestateref';
import apiConfig from '../apiConfig';
import axios from "axios";

import {
    Flex,
    Text,
    Box,
    FormControl,
    HStack,
    FormLabel,
    Container,
} from "@chakra-ui/react";

import { Select } from "chakra-react-select";

export function Selectfacility({ selectedfacility, setSelectedfacility }) {

    // select facility_code, facility_name from rc.apex_billing_facility

    let apiURL=apiConfig.REACT_APEX_BASE_API;
    const [selectedOption, setselectedOption] = useState();

    const facilities = [
        { label: "APEX-D", value: "APEX-D" },
        { label: "APEX-A", value: "APEX-A" },
        { label: "PresbyD", value: "THRPD" },
        { label: "MCA", value: "MCA" },
        { label: "MCL", value: "MCL" },
        { label: "DRMC", value: "DRMC" },
        { label: "Thhb Denton", value: "HHBD" },
        { label: "Hema MDPA", value: "HLK" },    
        { label: "Thhb Plano", value: "HHBP" },
    ]

    useEffect(()=> {
      console.log("CurrentSelectedOption", selectedOption, selectedfacility);
      if (selectedfacility) {
        let newOptionElement = facilitys.filter(row=> {return row['value'] == selectedfacility.value});
        console.log("Setting Facility to ", newOptionElement[0]);
        setselectedOption(newOptionElement[0]);
        return;
      }else {
          console.log("Clearing the CurrentSelectedOption for Facility");
          setselectedOption(null);
      }

    }, [selectedfacility]);

      const onFacilityChange = (val) => {

        
        console.log("Setting selected facility value to :", val);
        setselectedOption(val);

        if (val) {
        let selectedfacilityItem = facilities.filter(doctype=> doctype.value === val.value);
        console.log("Selected Document TYpe ",val,selectedfacilityItem, selectedfacilityItem[0]);
        setSelectedfacility(selectedfacilityItem[0]);
        }
      }

      let facilitys = facilities?.map(({value, label})=>{ return ({value: value, label: label}) }); // return ({staff.uid, staff.name}));c

      return(
          <Box>
          <FormControl w='300px'>
          <HStack>
          <FormLabel marginLeft={'2px'}>Facility</FormLabel>
          <Select
            isClearable={true}
            options={facilitys}
             value={selectedOption}
            placeholder="Select Facility..."
            closeMenuOnSelect={true}
            onChange={onFacilityChange}
          />
          </HStack>
          </FormControl>
        </Box>

      )

    }